import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingBrands: false,
  fetchBrandsError: '',
  brands: [],
  totalBrands: 0,
  page: 1,
  pageSize: 50,
  summaryPage: 1,
  summaryPageSize: 50,
  sort: '',
  sortDir: 'asc',

  fetchingCreateBrand: false,
  fetchCreateBrandError: '',
  fetchingEditBrandsUrls: false,
  fetchEditBrandsUrlsError: '',
  fetchingEditBrandsProducts: false,
  fetchEditBrandsProductsError: '',

  deletingBrands: false,
  deleteBrandsError: '',

  brandsSummary: [],
  fetchingBrandsSummary: false,
  fetchBrandsSummaryError: '',

  customBrands: [],
  fetchingCustomBrands: false,
  fetchCustomBrandsError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
