import * as actions from './actions'
import mutations from './mutations'

const state = {
  fetchingCategories: false,
  fetchCategoriesError: '',
  categories: [],
  totalCategories: 0,
  page: 1,
  pageSize: 50,
  summaryPage: 1,
  summaryPageSize: 50,
  sort: '',
  sortDir: 'asc',

  fetchingCreateCategory: false,
  fetchCreateCategoryError: '',

  fetchingEditCategoriesUrls: false,
  fetchEditCategoriesUrlsError: '',

  deletingCategories: false,
  deleteCategoriesError: '',
  fetchingEditCategoriesProducts: false,
  fetchEditCategoriesProductsError: '',

  categoriesSummary: [],
  fetchingCategoriesSummary: false,
  fetchCategoriesSummaryError: '',

  customCategories: [],
  fetchingCustomCategories: false,
  fetchCustomCategoriesError: '',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
