import * as types from '../../types'
import allErrors from './errorKeys'

export default {
  [types.ADMIN_FETCHING_CLIENTS](state) {
    Object.assign(state, { fetchingClients: true, fetchClientsError: '' })
  },
  [types.ADMIN_FETCHING_CLIENTS_SUCCESS](state, { clients, totalRecords }) {
    Object.assign(state, { fetchingClients: false, clients, totalRecords })
  },
  [types.ADMIN_FETCHING_CLIENTS_ERROR](state, fetchClientsError) {
    Object.assign(state, { fetchingClients: false, fetchClientsError })
  },

  [types.ADMIN_FETCHING_APP_FEATURES](state) {
    Object.assign(state, { fetchingAppFeatures: true, fetchAppFeaturesError: '' })
  },
  [types.ADMIN_FETCHING_APP_FEATURES_SUCCESS](state, { appFeatures, totalRecords }) {
    Object.assign(state, { fetchingAppFeatures: false, appFeatures, totalRecords })
  },
  [types.ADMIN_FETCHING_APP_FEATURES_ERROR](state, fetchAppFeaturesError) {
    Object.assign(state, { fetchingAppFeatures: false, fetchAppFeaturesError })
  },
  [types.ADMIN_CREATING_CLIENT](state) {
    Object.assign(state, { creatingClient: true, creatingClientError: '' })
  },
  [types.ADMIN_CREATE_CLIENT_SUCCESS](state, { client }) {
    Object.assign(state, { creatingClient: false, client: [...state.clients, client], creatingClientError: '' })
  },
  [types.ADMIN_CREATE_CLIENT_ERROR](state, creatingClientError) {
    Object.assign(state, { creatingClient: false, creatingClientError })
  },
  [types.ADMIN_FETCHING_DOMAINS](state) {
    Object.assign(state, { fetchingDomains: true, fetchDomainsError: '' })
  },
  [types.ADMIN_FETCHING_DOMAINS_SUCCESS](state, {
    domains, totalRecords, isInfinityScroll, page, pageSize,
  }) {
    Object.assign(state, {
      fetchingDomains: false,
      domains: isInfinityScroll ? [...state.domains, ...domains] : domains,
      totalDomains: totalRecords,
      pageSizeDomains: pageSize,
      pageDomains: page,
    })
  },
  [types.ADMIN_FETCHING_DOMAINS_ERROR](state, fetchDomainsError) {
    Object.assign(state, { fetchingDomains: false, fetchDomainsError })
  },
  [types.ADMIN_UPDATING_CLIENT](state) {
    Object.assign(state, { updatingClient: true, updatingClientError: '' })
  },
  [types.ADMIN_UPDATE_CLIENT_SUCCESS](state) {
    Object.assign(state, { updatingClient: false })
  },
  [types.ADMIN_UPDATE_CLIENT_ERROR](state, updatingClientError) {
    Object.assign(state, { updatingClient: false, updatingClientError })
  },
  [types.ADMIN_FETCHING_BECOME_CLIENT](state) {
    Object.assign(state, { fetchingBecomeClient: true, fetchBecomeClientError: '' })
  },
  [types.ADMIN_FETCHING_BECOME_CLIENT_SUCCESS](state) {
    Object.assign(state, { fetchingBecomeClient: false })
  },
  [types.ADMIN_FETCHING_BECOME_CLIENT_ERROR](state, fetchBecomeClientError) {
    Object.assign(state, { fetchingBecomeClient: false, fetchBecomeClientError })
  },
  [types.ADMIN_FETCHING_LOGOUT_ALL_USERS_OF_CLIENT](state) {
    Object.assign(state, { fetchingLogoutAllUsers: true, fetchLogoutAllUsersError: '' })
  },
  [types.ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_SUCCESS](state) {
    Object.assign(state, { fetchingLogoutAllUsers: false })
  },
  [types.ADMIN_LOGOUT_ALL_USERS_OF_CLIENT_ERROR](state, fetchLogoutAllUsersError) {
    Object.assign(state, { fetchingLogoutAllUsers: false, fetchLogoutAllUsersError })
  },
  [types.ADMIN_FETCHING_URLS]({ urlsModule }) {
    Object.assign(urlsModule, { fetchingUrls: true, fetchingUrlsError: '' })
  },
  [types.ADMIN_FETCHING_URLS_SUCCESS]({ urlsModule }, { urls, totalRecords }) {
    Object.assign(urlsModule, {
      urls, totalRecords, fetchingUrls: false, fetchingUrlsError: '',
    })
  },
  [types.ADMIN_FETCHING_URLS_ERROR]({ urlsModule }, fetchingUrlsError) {
    Object.assign(urlsModule, { urls: [], fetchingUrls: false, fetchingUrlsError })
  },

  [types.ADMIN_FETCHING_URL_DETAILS](state) {
    Object.assign(state, { fetchingUrlDetails: true, fetchUrlDetailsError: '' })
  },
  [types.ADMIN_FETCHING_URL_DETAILS_SUCCESS](state, urlDetails) {
    Object.assign(state, { fetchingUrlDetails: false, urlDetails })
  },
  [types.ADMIN_FETCHING_URL_DETAILS_ERROR](state, fetchUrlDetailsError) {
    Object.assign(state, { fetchingUrlDetails: false, fetchUrlDetailsError })
  },

  [types.ADMIN_FETCHING_A_CLIENT_ERROR](state, fetchClientError) {
    Object.assign(state, { fetchClientError })
  },

  [types.ADMIN_UPDATING_URL_SCRAPE_STATUS_SUCCESS](state, status) {
    Object.assign(state, {
      urlDetails: {
        ...state.urlDetails,
        scrapeJob: {
          ...state.urlDetails.scrapeJob,
          status,
        },
      },
    })
  },
  [types.ADMIN_UPDATING_URL_SCRAPE_STATUS_ERROR](state, fetchUrlDetailsError) {
    Object.assign(state, { fetchingUrlDetails: false, fetchUrlDetailsError })
  },

  [types.ADMIN_FETCHING_EXTERNAL_JOBS](state) {
    Object.assign(state, {
      fetchingExternalJobs: true,
      fetchExternalJobsError: '',
      externalJobs: [],
    })
  },
  [types.ADMIN_FETCHING_EXTERNAL_JOBS_SUCCESS](state, { externalJobs, totalRecords }) {
    const mappedJobs = externalJobs.map((data) => ({ ...data, _id: data.jobId }))
    Object.assign(state, {
      fetchingExternalJobs: false,
      externalJobs: mappedJobs,
      externalJobsCount: totalRecords,
    })
  },
  [types.ADMIN_FETCHING_EXTERNAL_JOBS_ERROR](state, fetchExternalJobsError) {
    Object.assign(state, {
      fetchingExternalJobs: false,
      fetchExternalJobsError,
      externalJobs: [],
    })
  },

  [types.ADMIN_CLEAR_ERRORS](state, errorKeys) {
    let errors = errorKeys
    if (!errorKeys) {
      errors = allErrors
    }
    Object.assign(state, { ...errors })
  },
}
