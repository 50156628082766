import * as types from '../../types'
import * as categoriesApi from '../../../api/categories'

export const createCategory = async ({ commit }, {
  name,
  color,
  projectId,
}) => {
  commit(types.CATEGORIES_CREATE_CATEGORY_FETCHING)
  try {
    const payload = {
      name,
      color,
      projectId,
    }
    const data = await categoriesApi.createCategory(payload)
    return commit(types.CATEGORIES_CREATE_CATEGORY_SUCCESS, data)
  } catch (e) {
    return commit(types.CATEGORIES_CREATE_CATEGORY_ERROR, e.message)
  }
}

export const fetchCategories = async ({ commit }, fetchParams = {}) => {
  commit(types.CATEGORIES_FETCHING_CATEGORIES)

  try {
    const data = await categoriesApi.getAll(fetchParams)
    return commit(types.CATEGORIES_RECEIVE_CATEGORIES, data)
  } catch (e) {
    return commit(types.CATEGORIES_RECEIVE_CATEGORIES_ERROR, e.message)
  }
}

export const fetchCategoriesSummary = async ({
  commit, state: {
    summaryPageSize,
  },
}, fetchParams = {}) => {
  commit(types.CATEGORIES_FETCHING_CATEGORIES_SUMMARY)

  try {
    const { isInfinityScroll, ...rest } = fetchParams
    const params = { ...rest, pageSize: summaryPageSize }

    const data = await categoriesApi.getAll(params)
    return commit(types.CATEGORIES_RECEIVE_CATEGORIES_SUMMARY, { ...data, isInfinityScroll })
  } catch (e) {
    return commit(types.CATEGORIES_RECEIVE_CATEGORIES_SUMMARY_ERROR, e.message)
  }
}

export const fetchCustomCategories = async ({
  commit, state: {
    summaryPageSize,
  },
}, fetchParams = {}) => {
  commit(types.CATEGORIES_FETCHING_CUSTOM_CATEGORIES)

  try {
    const { isInfinityScroll, ...rest } = fetchParams
    const params = { ...rest, pageSize: summaryPageSize }

    const data = await categoriesApi.getAll(params)
    return commit(types.CATEGORIES_RECEIVE_CUSTOM_CATEGORIES, { ...data, isInfinityScroll })
  } catch (e) {
    return commit(types.CATEGORIES_RECEIVE_CUSTOM_CATEGORIES_ERROR, e.message)
  }
}

export const setPage = ({ commit }, { page }) => {
  commit(types.CATEGORIES_SET_PAGE, page)
}

export const setPageSize = ({ commit }, { pageSize }) => {
  commit(types.CATEGORIES_SET_PAGESIZE, pageSize)
}

export const setSort = ({ commit }, { sort, sortDir }) => {
  commit(types.CATEGORIES_SET_SORT, sort)
  commit(types.CATEGORIES_SET_SORTDIR, sortDir)
}

export const editCategoriesUrls = async ({ commit }, {
  op,
  categoryIds,
  projectId,
  urlIds,
  filters = {},
}) => {
  commit(types.CATEGORIES_FETCHING_EDIT_CATEGORIES_URLS)

  try {
    const payload = {
      op,
      categoryIds,
      urlIds,
      projectId,
      filters,
    }
    const data = await categoriesApi.editUrls(payload)
    return commit(types.CATEGORIES_EDIT_CATEGORIES_URLS_SUCCESS, data)
  } catch (e) {
    return commit(types.CATEGORIES_EDIT_CATEGORIES_URLS_ERROR, e.message)
  }
}

export const deleteCategories = async ({ commit }, {
  categoryIds,
  filters = {},
  projectId,
}) => {
  commit(types.CATEGORIES_DELETING_CATEGORIES, true)

  try {
    await categoriesApi.deleteCategories({
      categoryIds,
      filters,
      projectId,
    })
    return commit(types.CATEGORIES_DELETING_CATEGORIES, false)
  } catch (e) {
    return commit(types.CATEGORIES_DELETE_CATEGORIES_ERROR, e.message)
  }
}

export const editCategoriesProducts = async ({ commit }, {
  op,
  categoryIds,
  projectId,
  productIds,
  filters = {},
}) => {
  commit(types.CATEGORIES_FETCHING_EDIT_CATEGORIES_PRODUCTS)

  try {
    const payload = {
      op,
      categoryIds,
      productIds,
      projectId,
      filters,
    }
    const data = await categoriesApi.editProducts(payload)
    return commit(types.CATEGORIES_EDIT_CATEGORIES_PRODUCTS_SUCCESS, data)
  } catch (e) {
    return commit(types.CATEGORIES_EDIT_CATEGORIES_PRODUCTS_ERROR, e.message)
  }
}

// eslint-disable-next-line max-len
export const clearErrors = async ({ commit }, errorKeys = {}) => commit(types.CATEGORIES_CLEAR_ERRORS, errorKeys)
